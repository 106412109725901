import { handleResponseError } from "../../utils/error-utils/response-error-handlers";

export async function findUser(userId: string): Promise<Record<string, unknown>> {
  return await fetch(`${process.env.GATSBY_API_BASE}/analytics_user/${userId}`, {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
    },
  }).then(async (res: Response): Promise<Record<string, unknown>> => {
    if (!res.ok) {
      await handleResponseError(res);
    }

    return res.json();
  });
}
