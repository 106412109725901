declare global {
  interface Window {
    dataLayer: any;
    analytics: any | null;
    locationsList?: string[];
  }
}

export function getBrowserFeaturesClasses(): string[] | undefined {
  if (typeof document === "undefined") {
    return;
  }

  const classes = ["js"];

  try {
    document.querySelector(":focus-visible");
    classes.push("focusvisible");
  } catch (error) {
    // Do nothing
  }

  return classes;
}

export function getWindow(): Window | null {
  return typeof window !== "undefined" ? window : null;
}

export function getNavigator(): Navigator | null {
  return typeof navigator !== "undefined" ? navigator : null;
}

export function getCurrentURLSearchParams(): URLSearchParams | undefined {
  if (getWindow()) {
    return new URLSearchParams(getWindow()?.location.search);
  }
}

export function getBestLocale(): "de" | "es" | undefined {
  const navigatorLanguage = getNavigator()?.language;
  if (navigatorLanguage === undefined) {
    return;
  }

  if (/^de\b/.test(navigatorLanguage)) {
    return "de";
  }

  return "es";
}

// Copied from https://stackoverflow.com/a/25490531/5664988
export const getCookieValue = (name: string): string | undefined => {
  if (typeof document === "undefined") {
    return;
  }

  return document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() ?? "";
};

export const setCookie = (
  name: string,
  value: string,
  path: string,
  daysToExpire?: number
): string => {
  let cookieString = `${name}=${value}; path=${path}`;

  if (daysToExpire !== undefined) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);
    cookieString += `; expires=${expirationDate.toUTCString()}`;
  }

  document.cookie = cookieString;

  return cookieString;
};

export const getPreviousPath = (): string | null => {
  const w = getWindow();

  if (!w) {
    return null;
  }

  w.locationsList = w.locationsList ?? [document.referrer];

  if (w.locationsList[w.locationsList.length - 1] !== w.location.href) {
    w.locationsList.push(w.location.href);
  }

  return w.locationsList[w.locationsList.length - 2];
};

export function getMobileOS(): "Android" | "iOS" | "Unknown" {
  const userAgent = getNavigator()?.userAgent ?? "";

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  return "Unknown";
}
