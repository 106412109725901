import { getAnonymousId } from "../../utils/analytics";
import { getCookieValue, getWindow } from "../../utils/browser-features";
import { handleFindUserErrors } from "../../utils/error-utils/catch-error-handlers";
import { findUser } from "../data/analytics-repository";

export const setupAnalytics = async (): Promise<void> => {
  const w: Window | null = getWindow();
  const writeKey = process.env.GATSBY_SEGMENT_WRITE_KEY;

  if (!w || !writeKey) {
    return;
  }

  const userId = getCookieValue("ajs_user_id");
  const anonymousId = getAnonymousId();

  w.analytics.reset();

  if (!userId) {
    w.analytics.load(writeKey);

    return;
  }

  try {
    const user = await findUser(userId);

    w.analytics.identify(userId, user, { anonymousId });
    w.analytics.load(writeKey);
  } catch (error) {
    handleFindUserErrors(error as Error, "setupAnalytics", userId);

    w.analytics.load(writeKey);
  }
};
